<template>
  <div
    class="noPosition"
    id="right"
    ref="noPosition"
    :style="{
      width: wrapWidth + 'px',
      height: getImgWrapHeight(wrapWidth, imgList.length) + 'px',
    }"
  >
    <template v-for="(item, index) in signList">
      <!-- 骑缝章 -->
      <template v-if="item.signType == 4">
        <vue-draggable-resizable
          class-name="my-drag-class4"
          v-for="(num, index2) in pageNum"
          :x="item.x"
          :y="calcPositionY(item.y, index2 + 1)"
          :style="{
            transform: `translate(${item.x}px, ${calcPositionY(
              item.y,
              index2 + 1
            )}px)`,
          }"
          :w="getWidthAndHeight(item.width / pageNum)"
          :h="getWidthAndHeight(item.height)"
          :key="`${index}${index2}${item.signType}`"
          :parent="true"
          :resizable="false"
          @dragging="(x, y) => onDragging4({ x, y }, index, index2, item)"
          @dragstop="onDragStop"
        >
          <div class="test">
            <i class="el-icon-delete2" @click.stop="deleteOneSign(index)"></i>
            <div
              class="img-wrap"
              :style="{
                width: `${getWidthAndHeight(item.width / pageNum)}px`,
                height: `${getWidthAndHeight(item.height)}px`,
                overflow: `hidden`,
                position: 'relative',
              }"
            >
              <img
                :src="item.sealKey"
                alt=""
                :style="{
                  position: 'absolute',
                  top: 0,
                  height: '100%',
                  transform: `translate(-${getWidthAndHeight(
                    item.width / pageNum
                  ) *
                    (num - 1)}px`,
                }"
              />
            </div>
            <div class="signTitle">
              <img
                class="currenDateBase64"
                :src="
                  textBecomeImg(SIGN_SEAL_TYPE_TEXT[item.signType], 14, '#000')
                "
                alt=""
              />
            </div>
          </div>
        </vue-draggable-resizable>
      </template>

      <!-- 签章 -->
      <template v-else-if="item.signType === 3">
        <vue-draggable-resizable
          class-name="my-drag-class"
          :x="item.x"
          :y="calcPositionY(item.y, item.page)"
          :w="getWidthAndHeight(item.width)"
          :h="getWidthAndHeight(item.height)"
          :key="index"
          :style="{
            transform: `translate(${item.x}px, ${calcPositionY(
              item.y,
              item.page
            )}px)`,
          }"
          :parent="true"
          :resizable="false"
          @dragging="
            (x, y) => drag({ x, y }, index, item.signType, item, item.page)
          "
          @dragstop="onDragStop"
        >
          <div class="test">
            <i class="el-icon-delete" @click.stop="deleteOneSign(index)"></i>
            <div v-if="item.dateFlag" class="time">
              <img class="currenDateBase64" :src="currenDateBase64" alt="" />
              <i class="el-icon-delete" @click="item.dateFlag = 0"></i>
            </div>
            <img :src="item.sealKey" alt="" />
            <div class="signTitle">
              <img
                class="currenDateBase64"
                :src="
                  textBecomeImg(SIGN_SEAL_TYPE_TEXT[item.signType], 14, '#000')
                "
                alt=""
              />
            </div>
          </div>
        </vue-draggable-resizable>
      </template>
      <!-- 签章 -->

      <!-- 签名 可以缩放大小-->
      <template v-else>
        <vue-draggable-resizable
          class-name="my-drag-class user-position"
          :x="item.x"
          :y="calcPositionY(item.y, item.page)"
          :w="getWidthAndHeight(item.width)"
          :h="getWidthAndHeight(item.height)"
          :minWidth="43"
          :minHeight="29"
          :key="index"
          :style="{
            transform: `translate(${item.x}px, ${calcPositionY(
              item.y,
              item.page
            )}px)`,
            width:
              (item.resize ? item.width : getWidthAndHeight(item.width)) + 'px',
            height:
              (item.resize ? item.height : getWidthAndHeight(item.height)) +
              'px',
          }"
          lock-aspect-ratio
          :parent="true"
          :resizable="true"
          :handles="['br']"
          @resizing="
            (left, top, width, height) =>
              onSealResizing({
                left,
                top,
                width,
                height,
                index,
                page: item.page,
                item,
              })
          "
          @resizestop="
            (left, top, width, height) =>
              onSealResizeStop({
                left,
                top,
                width,
                height,
                index,
                page: item.page,
              })
          "
          @dragging="
            (x, y) => drag({ x, y }, index, item.signType, item, item.page)
          "
          @dragstop="
            (left, top) => onDragStop({ left, top, item, page: item.page })
          "
        >
          <div class="test">
            <i class="el-icon-delete" @click.stop="deleteOneSign(index)"></i>
            <div v-if="item.dateFlag" class="time">
              <img class="currenDateBase64" :src="currenDateBase64" alt="" />
              <i class="el-icon-delete" @click="item.dateFlag = 0"></i>
            </div>
            <img :src="item.sealKey" alt="" />
            <div class="signTitle">
              <img
                class="currenDateBase64"
                :src="
                  textBecomeImg(SIGN_SEAL_TYPE_TEXT[item.signType], 14, '#000')
                "
                alt=""
              />
            </div>
          </div>
        </vue-draggable-resizable>
      </template>
      <!-- 签名 -->
    </template>
    <template v-for="(item, index) in currentFile.oldPositionList">
      <template v-if="item.signType == 4">
        <div
          :class="'signPosition2'"
          v-for="(num, index2) in pageNum"
          :key="index + 'signPosition2' + index2"
          :style="{
            right: '0px',
            top: calcPositionY(item.y, index2 + 1, ratio) + 'px',
            width: `${getWidthAndHeight(item.width / pageNum)}px`,
            height: `${getWidthAndHeight(item.height)}px`,
          }"
        >
          <img
            :src="item.sealKey"
            alt=""
            :style="{
              transform: `translate(-${getWidthAndHeight(item.width / pageNum) *
                (num - 1)}px`,
            }"
          />
        </div>
      </template>
      <template v-else>
        <div
          :class="'signPosition'"
          :key="index + 'oldPositionList'"
          :style="{
            left: item.x * ratio + 'px',
            top: calcPositionY(item.y, item.page, ratio) + 'px',
            width: `${getWidthAndHeight(item.width)}px`,
            height: `${getWidthAndHeight(item.height)}px`,
          }"
        >
          <div v-if="item.dateFlag" class="time">
            <img
              class="currenDateBase64"
              :src="textBecomeImg(item.signDate, 14, '#000')"
              alt=""
            />
          </div>
          <img :src="item.sealKey" alt="" />
        </div>
      </template>
    </template>
    <ul class="file-img-list">
      <li
        class="file-img-item"
        :class="{
          'seal4-bg': imgList.length > 1 && type === 2,
          'show-page-footer': isCurImgLoaded(index),
        }"
        v-for="(item, index) in imgList"
        :key="index"
      >
        <img
          :src="item"
          alt=""
          @load="onImgLoaded(index)"
          :style="{ height: computedY + 'px' }"
        />
        <div class="page-content" v-if="index < imgList.length - 1">
          <SvgIcon type="icon_top_left" class="page_icon icon_top_left" />
          <SvgIcon type="icon_top_right" class="page_icon icon_top_right" />
          <SvgIcon type="icon_bottom_left" class="page_icon icon_bottom_left" />
          <SvgIcon
            type="icon_bottom_right"
            class="page_icon icon_bottom_right"
          />
          <!-- <div class="page-tip">签署位置不能指定在分页处</div> -->
        </div>
      </li>
      <template v-if="!currentFile.fileHtml">
        <li
          class="file-variable"
          v-for="variable in hasValueParamList"
          :key="variable.componentId"
          :style="{
            left: variable.x * ratio + 'px',
            top: calcPositionY(variable.y, variable.page, true) + 'px',
            width:
              variable.fontSize * ratio >= 12
                ? variable.width * ratio + 'px'
                : (variable.width * ratio) /
                    ((variable.fontSize * ratio) / 12) +
                  'px',
            height: variable.height * ratio + 'px',
            fontSize:
              variable.fontSize * ratio >= 12
                ? variable.fontSize * ratio + 'px'
                : 12 + 'px',
            fontFamily: getCurFontFamilyByValue(variable.font).name,
          }"
        >
          <div
            :style="{
              transform: `scale(${
                variable.fontSize * ratio >= 12
                  ? 1
                  : (variable.fontSize * ratio) / 12
              })`,
              display: 'inline-block',
              transformOrigin: '0 0',
            }"
          >
            {{ variable.varValue ? variable.varValue : variable.varTitle }}
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
  import { getCurFontFamilyByValue } from '@/common/font'
  import { mapState } from 'vuex'
  import { ImagePreview } from 'vant'
  import moment from 'moment'
  import {
    reTranslateSealWidthAndHeight,
    translateSealWidthAndHeight,
  } from '@/utils/pxtorem'
  import textBecomeImg from '@/utils/textBecomeImg'
  // import Hammer from 'hammerjs';
  import {
    getBodyClientRect,
    getFileImgHeight,
    getImgWrapHeight,
  } from '@/utils/dom'
  import { paginationHeight } from '@/common/filePreviewConfig'
  import { isEmptyVariableValue } from '@/utils/helper'

  const timeHeight = 20
  const pageFooterHeight = 20
  export default {
    props: {
      ratio: {
        type: Number,
        default: 343 / 595,
      },
      // 发起时的当前文件
      launchCurrentFile: {
        type: Object,
        default: () => ({}),
      },
      curLauncher: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        currenDate: moment().format('YYYY-MM-DD'),
        currenDateBase64: textBecomeImg(
          moment().format('YYYY-MM-DD'),
          14,
          '#000'
        ),
        wrapWidth: 0,
        wrapHeight: 0,

        // 已经加载成功的 图片的 index list
        loadedImgIndexList: [],
        SIGN_SEAL_TYPE_TEXT: {
          0: '个人签署',
          1: '经办人签署',
          2: '法人签署',
          3: '企业签章',
          4: '骑缝章',
        },
        masterId: undefined,
      }
    },
    computed: {
      ...mapState({
        // 签署时的 当前文件
        currentFile: state => state.contractSign.currentFile,
        pageNum: state => state.contractSign.pageNum,
        // ratio: state => state.contractSign.ratio,
        ratio2: state => state.contractSign.ratio2,
        computedY: state => state.contractSign.computedY,
        scrollTop: state => state.contractSign.scrollTop,
        operateType: state => state.contractSign.operateType,
      }),
      signList() {
        if (this.masterId) {
          return this.launchCurrentFile.signData.signList
        }
        return this.currentFile.signData.signList
      },
      imgList() {
        if (this.masterId) {
          return this.launchCurrentFile.imgList || []
        }
        return this.currentFile.fileKeyList || []
      },
      type() {
        if (
          this.operateType === 1 ||
          this.operateType === 2 ||
          this.operateType === 3
        ) {
          return 1
        }
        return 2
      },
      hasValueParamList() {
        if (this.currentFile && this.currentFile.allVarList) {
          return this.currentFile.allVarList.filter(
            param => !isEmptyVariableValue(param.varValue)
          )
        }
        return []
      },
    },
    created() {
      this.masterId = this.$route.query.masterId
    },
    mounted() {
      const { clientWidth } = getBodyClientRect()
      this.wrapWidth = clientWidth

      this.$nextTick(() => {
        const height = getFileImgHeight()
        this.wrapHeight = height
      })
    },
    methods: {
      getImgWrapHeight,
      getCurFontFamilyByValue,
      textBecomeImg,

      onImgLoaded(index) {
        this.loadedImgIndexList = [...this.loadedImgIndexList, index]
      },

      isCurImgLoaded(index) {
        return this.loadedImgIndexList.includes(index)
      },

      // pxToRem,
      getWidthAndHeight(value) {
        const { clientWidth } = getBodyClientRect()
        this.wrapWidth = clientWidth
        return this.translateSealWidthAndHeight(this.wrapWidth, value)
      },
      translateSealWidthAndHeight,
      showImg(index) {
        ImagePreview({
          images: this.imgList,
          startPosition: index,
          closeOnPopstate: true,
        })
      },
      deleteOneSign(index) {
        this.$store.commit('contractSign/deteleSeal', index)
      },
      getCurrentPage(y) {
        const disY = y
        if (disY < this.computedY) {
          return {
            page: 1,
            y,
          }
        }
        return {
          page:
            Math.floor(disY / this.computedY) + 1 > this.imgList.length
              ? this.imgList.length
              : Math.floor(disY / this.computedY) + 1,
          y: disY % this.computedY,
        }
      },
      drag(position, index, type, item, page) {
        position.y -= (page - 1) * paginationHeight
        this.$emit('pzDisabled')
        document.querySelector('body').style.overflow = 'hidden'
        // document.querySelector('#scrollWrapId').style.overflowY = 'hidden'
        this.onDragging(position, index, type, item, page)
      },
      onDragging(position, index, type, item) {
        const h = this.getWidthAndHeight(item.height)
        if (position.y > this.wrapHeight * this.pageNum - h - 20) {
          position.y = this.wrapHeight * this.pageNum - h - 20
        }
        const y = position.y % this.wrapHeight
        if (y > this.wrapHeight - h - 20) {
          position.y -= h + 20
        }
        const positinCom = this.getCurrentPage(position.y)
        this.$store.commit('contractSign/setSealPosition', {
          index,
          x: position.x,
          y: positinCom.y,
          page: positinCom.page,
        })
      },
      onDragStop({ left, top, item }) {
        if (item) {
          this.$emit('pzEnabled')
          const { width, height } = item
          console.log(height)
          // 边界判断
          const { y } = this.getCurrentPage(top)
          // 超出一页高度 要还原
          if (y + height + timeHeight + pageFooterHeight > this.wrapHeight) {
            const calcHeight =
              this.wrapHeight - timeHeight - pageFooterHeight - y
            // 防止会因为y值很大导致签名的大小太小
            item.height = calcHeight > 43 ? calcHeight : 43
          } else {
            item.height = height > 0 ? height : 0
          }
          // 超出一页宽度 要还原
          if (left + width > this.wrapWidth) {
            //
            item.width = this.wrapWidth - left
          }

          document.querySelector('body').style.overflow = 'visible'
          // document.querySelector('#scrollWrapId').style.overflowY = 'visible'
        } else {
          this.$emit('pzEnabled')
          document.querySelector('body').style.overflow = 'visible'
        }
      },
      onSealResizing({
        left,
        top,
        width,
        height,
        index,
        page: currentPage,
        item,
      }) {
        item.resize = true
        top -= paginationHeight * (currentPage - 1)
        this.$emit('pzDisabled')
        if (top + height + 20 > this.wrapHeight * this.pageNum) {
          top = this.wrapHeight * this.pageNum - height - 20
        }
        const { page, y } = this.getCurrentPage(top)

        this.$store.commit('contractSign/setSealPosition', {
          index,
          x: left,
          y,
          page,
          width,
          height,
          wrapWidth: this.wrapWidth,
        })
      },
      // resize 停止的时候触发，判断当前签名的下边界时候过界
      onSealResizeStop({ left, top, width, height, index, page: currentPage }) {
        top -= paginationHeight * (currentPage - 1)
        this.$emit('pzEnabled')
        const { page, y } = this.getCurrentPage(top)
        // y 当前签名的 top
        // 20 日期的高度
        // y + height + 20 代表签名的下边界
        // this.wrapHeight 代表一页的高度
        // 如果当前签名的底部超出了页面的底部，则强制设置该签名的高度
        if (y + height + timeHeight + pageFooterHeight >= this.wrapHeight) {
          this.$store.commit('contractSign/setSealPosition', {
            index,
            x: left,
            y,
            page,
            width,
            height:
              this.wrapHeight - timeHeight - pageFooterHeight - y < 20
                ? 20
                : this.wrapHeight - timeHeight - pageFooterHeight - y,
            wrapWidth: this.wrapWidth,
          })
          // 设置完值后不会触发页面的更新，强制刷新
          this.$forceUpdate()
        } else {
          // if (height < 38 * this.ratio * 0.75) {
          //   height = 38
          // }
          // if (width < 93 * this.ratio * 0.75) {
          //   width = 93
          // }
          this.$store.commit('contractSign/setSealPosition', {
            index,
            x: left,
            y,
            page,
            width,
            height,
            wrapWidth: this.wrapWidth,
          })
          // 设置完值后不会触发页面的更新，强制刷新
          this.$forceUpdate()
        }
      },
      // 骑缝章
      onDragging4(position, index, page, item) {
        this.$emit('pzDisabled')
        document.querySelector('body').style.overflow = 'hidden'
        // document.querySelector('#scrollWrapId').style.overflowY = 'hidden'
        let y = position.y - (this.computedY + paginationHeight) * page
        if (y < 0) {
          y = 0
        }
        if (y > this.computedY - this.getWidthAndHeight(item.height)) {
          y = this.computedY - this.getWidthAndHeight(item.height)
        }
        this.$store.commit('contractSign/setSealPosition', {
          index,
          x: this.wrapWidth - this.getWidthAndHeight(item.width / this.pageNum),
          y,
          page: 1,
        })
      },

      calcPositionY(y, page, ratio) {
        if (ratio) {
          return (
            y * this.ratio +
            (page - 1) * (this.computedY + paginationHeight * this.ratio2)
          )
        }
        return (
          y + (page - 1) * (this.computedY + paginationHeight * this.ratio2)
        )
      },
    },
  }
</script>
<style lang="less" scoped>
  .user-position-style {
    background: rgba(233, 249, 243, 0.82);
    border: 1px dashed #61d0aa;
  }

  .noPosition {
    position: relative;
    width: 100%;
    // padding: 0 16px;
    .file-img-list {
      width: 100%;
      overflow: hidden;
      position: relative;
      .file-img-item {
        position: relative;
        &:after {
          text-align: center;
          content: '签署位置不能指定在分页处';
          display: none;
          // width: 100%;
          height: 20px;
          position: absolute;
          z-index: 1;
          left: 0;
          bottom: -20px;
          right: 0;
          font-size: 10px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #f15643;
          line-height: 20px;
          border: 1px dashed #f15643;
          box-sizing: border-box;
        }
        &.show-page-footer {
          &::after {
            display: inline-block;
          }
        }

        img {
          width: 100%;
        }
        .page-content {
          // padding: 28px;
          background: #fff;
          position: relative;
          .page_icon {
            font-size: 11px;
            position: absolute;
            z-index: 1;
          }
          .icon_top_left {
            left: 18px;
            top: -34px;
          }
          .icon_top_right {
            right: 18px;
            top: -34px;
          }
          .icon_bottom_left {
            left: 18px;
            bottom: -32px;
          }
          .icon_bottom_right {
            right: 18px;
            bottom: -32px;
          }
          .page-tip {
            font-size: 11px;
            height: 23px;
            color: #f15643;
            line-height: 16px;
            text-align: center;
            line-height: 22px;
            border: 1px dashed #f15643;
          }
        }
      }
      .file-img-item + .file-img-item {
        margin-top: 20px;
      }
      .seal4-bg {
        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: '骑缝章签署区';
          width: 50px;
          height: 100%;
          color: #95befd;
          background: url('../../../assets/imgs/sealManage/seal_bg1.svg');
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          box-sizing: border-box;
          text-align: center;
          font-size: 28px;
        }
      }
      .file-variable {
        position: absolute;
      }
    }
    .my-drag-class4 {
      border: 1px dashed rgba(22, 118, 255, 1);
      position: absolute;
      display: flex;
      align-items: center;
      z-index: 2 !important;
      img {
      }
    }
    .my-drag-class {
      // width: 106px !important;
      border: 1px dashed #ffc000;
      background: rgba(255, 249, 218, 0.6);
      position: absolute;
      display: flex;
      z-index: 2 !important;
      justify-content: center;
      align-items: center;
      .test {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .time {
        border: 1px dashed #ffc000;
        background: rgba(255, 249, 218, 0.6);
      }
      &.user-position {
        .user-position-style();
        .time {
          .user-position-style();
        }
        .signTitle {
          .user-position-style();
        }
      }
    }
    .signPosition {
      position: absolute;
      z-index: 1;
      //width: 106px !important;
      //height: 56px !important;
      background: transparent;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #5f6368;
      line-height: 56px;
      display: flex;
      // justify-content: center;
      align-items: center;
      img {
        //width: 74px;
        //height: 34px;
        width: 100%;
        height: 100%;
        // object-fit: contain;
      }
    }
    .signPosition2 {
      position: absolute;
      z-index: 1;
      background: transparent;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #5f6368;
      line-height: 56px;
      overflow: hidden;
      display: flex;
      // justify-content: center;
      align-items: center;
      img {
        height: 100%;
        // width: 74px;
        // height: 34px;
        // object-fit: contain;
        // margin-right: -37px;
      }
    }
    .sealStyle {
      width: 93px;
      height: 93px;
      border: 1px solid rgba(22, 118, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #5f6368;
      line-height: 56px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sealStyle2 {
      border: 1px dashed #677283;
      display: flex;
      background: transparent;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #5f6368;
      line-height: 56px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sealStyle4 {
      width: 43px;
      height: 86px;
      border: 1px solid rgba(22, 118, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #5f6368;
      line-height: 56px;
      position: absolute;
      overflow: hidden;
      img {
        width: 72px;
        height: 72px;
        margin-right: -36px;
      }
    }

    .el-icon-delete {
      background: url('../../../assets/imgs/signFile/delete.png') no-repeat
        center;
      width: 12px;
      height: 12px;
      background-size: 100%;
      position: absolute;
      top: -8px;
      left: -6px;
      z-index: 100;
      cursor: pointer;
    }
    .el-icon-delete2 {
      background: url('../../../assets/imgs/signFile/delete.png') no-repeat
        center;
      width: 12px;
      height: 12px;
      background-size: 100%;
      position: absolute;
      top: -21px;
      left: -6px;
      z-index: 100;
      cursor: pointer;
    }
    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      position: absolute;
      bottom: -20px;
      left: -1px;
      right: -1px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      font-size: 10px;
      padding: 0 4px;
      .currenDateBase64 {
        position: relative;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      .el-icon-delete {
        bottom: -6px;
        top: initial;
      }
    }
    .signTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      position: absolute;
      top: -20px;
      left: -1px;
      right: -1px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      border: 0.02667rem dashed #ffc000;
      background: rgba(255, 249, 218, 0.6);
      font-size: 10px;
      padding: 0 4px;
      .currenDateBase64 {
        position: relative;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .my-drag-class4 .signTitle {
      border-color: #1676ff;
      background: transparent;
    }
  }
</style>
