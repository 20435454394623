var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"sealAndName",staticClass:"sealAndName"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"wrap"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.type === 1 ? '放置签名' : '选择印章')+" "),_c('div',{staticClass:"icon",on:{"click":_vm.toggle}},[_vm._v("收起")])]),_c('div',{staticClass:"btn-left",on:{"click":_vm.handleRight}},[_c('img',{attrs:{"src":require("@/assets/imgs/signFile/icon_left.png"),"alt":""}})]),_c('div',{staticClass:"btn-right",on:{"click":_vm.handleLeft}},[_c('img',{attrs:{"src":require("@/assets/imgs/signFile/icon_right.png"),"alt":""}})]),(_vm.type === 1)?[_c('div',{staticClass:"content"},[(_vm.myInfo.userSignList && _vm.myInfo.userSignList.length !== 0)?_c('div',{staticClass:"des"},[_vm._v(" 点击签名或拖拽至需要签署的位置 ")]):_vm._e(),_c('div',{staticClass:"user-sign-container",attrs:{"id":"scrollWarp1"}},[_c('ul',{staticClass:"nameImg-list"},_vm._l((_vm.canUseUserSignList),function(seal,i){return _c('li',{key:i,class:_vm.currentSeal == seal.signIdE
                ? 'nameImg-item cur-seal'
                : 'nameImg-item',on:{"touchstart":function($event){return _vm.touchStart($event, seal, i)},"touchmove":function($event){return _vm.touchMove($event)},"touchend":function($event){return _vm.touchEnd($event)}}},[(seal.defaultSignature)?_c('div',{staticClass:"tip"},[_vm._v("默认")]):_vm._e(),(seal.count)?_c('div',{staticClass:"count"},[_vm._v(_vm._s(seal.count))]):_vm._e(),_c('img',{attrs:{"src":seal.signatureKey,"alt":""}})])}),0),(
            (_vm.myInfo.userSignList.length < 4 && !_vm.signatureRequire) ||
            (_vm.signatureRequire === 2 && !_vm.canUseUserSignList.length)
          )?_c('div',{staticClass:"add-user-sign",on:{"click":_vm.addName}},[_c('SvgIcon',{staticClass:"add-sign-icon",attrs:{"type":"add"}}),_c('span',{staticClass:"add-sign-text"},[_vm._v("制作新签名")])],1):_vm._e()]),(!_vm.myInfo.userSignList || _vm.myInfo.userSignList.length === 0)?_c('div',{staticClass:"noName",on:{"click":_vm.addName}},[_c('img',{attrs:{"src":require("@/assets/imgs/add-icon.png"),"alt":""}}),_vm._v(" 您尚未可用签名，请先"),_c('span',[_vm._v("制作新签名")])]):_vm._e()])]:[_c('div',{staticClass:"content"},[_c('div',{staticClass:"des"},[_vm._v("点击签名或拖拽至需要签署的位置")]),_c('ul',{staticClass:"tabs-list"},[_c('li',{staticClass:"tabs-item",class:_vm.sealType === 0 ? 'tab-active' : '',on:{"click":function($event){return _vm.toggleType(0)}}},[_vm._v(" 个人签名 ")]),_c('li',{staticClass:"tabs-item",class:_vm.sealType === 1 ? 'tab-active' : '',on:{"click":function($event){return _vm.toggleType(1)}}},[_vm._v(" 页面章 ")]),(_vm.pageNum > 1)?_c('li',{staticClass:"tabs-item",class:_vm.sealType === 2 ? 'tab-active' : '',on:{"click":function($event){return _vm.toggleType(2)}}},[_vm._v(" 骑缝章 ")]):_vm._e()]),(_vm.sealType === 0)?_c('div',{staticClass:"seal-name"},[_c('div',{staticClass:"seal-img user-sign-container",attrs:{"id":"scrollWarp1"}},[_c('ul',{staticClass:"nameImg-list"},_vm._l((_vm.canUseUserSignList),function(seal,i){return _c('li',{key:i,class:_vm.currentSeal == seal.signIdE
                  ? 'nameImg-item cur-seal'
                  : 'nameImg-item',on:{"touchstart":function($event){return _vm.touchStart($event, seal, i)},"touchmove":function($event){return _vm.touchMove($event)},"touchend":function($event){return _vm.touchEnd($event)}}},[(seal.defaultSignature)?_c('div',{staticClass:"tip"},[_vm._v("默认")]):_vm._e(),(seal.count)?_c('div',{staticClass:"count"},[_vm._v(_vm._s(seal.count))]):_vm._e(),_c('img',{attrs:{"src":seal.signatureKey,"alt":""}})])}),0),(_vm.myInfo.userSignList.length < 4 && !_vm.signatureRequire)?_c('div',{staticClass:"add-user-sign",on:{"click":_vm.addName}},[_c('SvgIcon',{staticClass:"add-sign-icon",attrs:{"type":"add"}}),_c('span',{staticClass:"add-sign-text"},[_vm._v("制作新签名")])],1):_vm._e()]),(!_vm.myInfo.userSignList || _vm.myInfo.userSignList.length === 0)?_c('div',{staticClass:"noName",on:{"click":_vm.addName}},[_c('img',{attrs:{"src":require("@/assets/imgs/add-icon.png"),"alt":""}}),_vm._v(" 您尚未可用签名，请先"),_c('span',[_vm._v("制作新签名")])]):_vm._e()]):(_vm.sealType === 1)?_c('ul',{staticClass:"sealImg-list"},[(!_vm.getAssign)?_c('div',{staticClass:"sealImg-box",attrs:{"id":"scrollWarp2"}},[_vm._l((_vm.sealList),function(seal,i){return _c('li',{key:i,class:_vm.currentSeal == seal.sealId
                ? 'sealImg-item cur-seal'
                : 'sealImg-item',on:{"touchstart":function($event){return _vm.touchStart($event, seal, i)},"touchmove":function($event){return _vm.touchMove($event)},"touchend":function($event){return _vm.touchEnd($event)}}},[(seal.sealName == '默认公章')?_c('div',{staticClass:"tip"},[_vm._v("默认")]):_vm._e(),(seal.count)?_c('div',{staticClass:"count"},[_vm._v(_vm._s(seal.count))]):_vm._e(),_c('img',{attrs:{"src":seal.sealUrl,"alt":""}})])}),(_vm.showMakeSealButton)?_c('li',{staticClass:"sealImg-item make-seal",on:{"click":_vm.handleToMakeSeal}},[_c('SvgIcon',{staticClass:"add-icon",attrs:{"type":"add"}}),_c('span',{staticClass:"add-text"},[_vm._v("制作印章")])],1):_vm._e()],2):_c('div',{staticClass:"sealImg-box",attrs:{"id":"scrollWarp2"}},[_c('li',{class:_vm.currentSeal == _vm.getAssign.sealId
                ? 'sealImg-item cur-seal'
                : 'sealImg-item',on:{"touchstart":function($event){return _vm.touchStart($event, _vm.getAssign)},"touchmove":function($event){return _vm.touchMove($event)},"touchend":function($event){return _vm.touchEnd($event)}}},[(_vm.getAssign.sealName == '默认公章')?_c('div',{staticClass:"tip"},[_vm._v(" 默认 ")]):_vm._e(),(_vm.getAssign.count)?_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.getAssign.count)+" ")]):_vm._e(),_c('img',{attrs:{"src":_vm.getAssign && _vm.getAssign.sealUrl,"alt":""}})])])]):(_vm.sealType === 2)?_c('ul',{staticClass:"sealImg-list"},[(!_vm.getAssign)?_c('div',{staticClass:"sealImg-box",attrs:{"id":"scrollWarp3"}},[_vm._l((_vm.sealList),function(seal,i){return _c('li',{key:i,staticClass:"sealImg-item",class:{
                'sealImg-item-gary':
                  _vm.isHasSealType4 && _vm.checkedSealType4 !== seal.sealId,
                'cur-seal': _vm.currentSeal == seal.sealId,
              },on:{"touchstart":function($event){return _vm.touchStart($event, seal, i)},"touchmove":function($event){return _vm.touchMove($event)},"touchend":function($event){return _vm.touchEnd($event)}}},[(seal.sealName == '默认公章')?_c('div',{staticClass:"tip"},[_vm._v(" 默认 ")]):_vm._e(),(seal.count)?_c('div',{staticClass:"count"},[_vm._v(_vm._s(seal.count))]):_vm._e(),_c('img',{attrs:{"src":seal.sealUrl,"alt":""}})])}),(_vm.showMakeSealButton)?_c('li',{staticClass:"sealImg-item make-seal",on:{"click":_vm.handleToMakeSeal}},[_c('SvgIcon',{staticClass:"add-icon",attrs:{"type":"add"}}),_c('span',{staticClass:"add-text"},[_vm._v("制作印章")])],1):_vm._e()],2):_c('div',{staticClass:"sealImg-box",attrs:{"id":"scrollWarp3"}},[_c('li',{class:_vm.currentSeal == _vm.getAssign.sealId
                ? 'sealImg-item cur-seal'
                : 'sealImg-item',on:{"touchstart":function($event){return _vm.touchStart($event, _vm.getAssign)},"touchmove":function($event){return _vm.touchMove($event)},"touchend":function($event){return _vm.touchEnd($event)}}},[(_vm.getAssign.sealName == '默认公章')?_c('div',{staticClass:"tip"},[_vm._v(" 默认 ")]):_vm._e(),(_vm.getAssign.count)?_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.getAssign.count)+" ")]):_vm._e(),_c('img',{attrs:{"src":_vm.getAssign.sealUrl,"alt":""}})]),(_vm.showMakeSealButton)?_c('li',{staticClass:"sealImg-item make-seal",on:{"click":_vm.handleToMakeSeal}},[_c('SvgIcon',{staticClass:"add-icon",attrs:{"type":"add"}}),_c('span',{staticClass:"add-text"},[_vm._v("制作印章")])],1):_vm._e()])]):_vm._e()])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }