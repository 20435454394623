<template>
  <div class="sealAndName" ref="sealAndName">
    <div class="wrap" v-show="show">
      <div class="header">
        {{ type === 1 ? '放置签名' : '选择印章' }}
        <div class="icon" @click="toggle">收起</div>
      </div>
      <div class="btn-left" @click="handleRight">
        <img src="@/assets/imgs/signFile/icon_left.png" alt="" />
      </div>
      <div class="btn-right" @click="handleLeft">
        <img src="@/assets/imgs/signFile/icon_right.png" alt="" />
      </div>
      <!-- <SvgIcon type="icon_left" class="btn-left" @click="handleRight" />
      <SvgIcon type="icon_left" class="btn-right" @click="handleLeft" /> -->
      <!-- 签名 -->
      <template v-if="type === 1">
        <div class="content">
          <div class="des" v-if="myInfo.userSignList && myInfo.userSignList.length !== 0">
            点击签名或拖拽至需要签署的位置
          </div>
          <div class="user-sign-container" id="scrollWarp1">
            <!-- 添加 签名 -->
            <ul class="nameImg-list">
              <li @touchstart="touchStart($event, seal, i)" @touchmove="touchMove($event)" @touchend="touchEnd($event)"
                :class="currentSeal == seal.signIdE
                  ? 'nameImg-item cur-seal'
                  : 'nameImg-item'
                  " v-for="(seal, i) in canUseUserSignList" :key="i">
                <div class="tip" v-if="seal.defaultSignature">默认</div>
                <div class="count" v-if="seal.count">{{ seal.count }}</div>
                <img :src="seal.signatureKey" alt="" />
              </li>
            </ul>
            <!-- 添加 签名 -->
            <div class="add-user-sign" @click="addName" v-if="
              (myInfo.userSignList.length < 4 && !signatureRequire) ||
              (signatureRequire === 2 && !canUseUserSignList.length)
            ">
              <SvgIcon type="add" class="add-sign-icon" />
              <span class="add-sign-text">制作新签名</span>
            </div>
          </div>

          <div v-if="!myInfo.userSignList || myInfo.userSignList.length === 0" class="noName" @click="addName">
            <img src="@/assets/imgs/add-icon.png" alt="" />
            您尚未可用签名，请先<span>制作新签名</span>
          </div>
        </div>
      </template>
      <!-- 印章 -->
      <template v-else>
        <div class="content">
          <div class="des">点击签名或拖拽至需要签署的位置</div>
          <ul class="tabs-list">
            <li class="tabs-item" :class="sealType === 0 ? 'tab-active' : ''" @click="toggleType(0)">
              个人签名
            </li>
            <li class="tabs-item" :class="sealType === 1 ? 'tab-active' : ''" @click="toggleType(1)">
              页面章
            </li>
            <li v-if="pageNum > 1" class="tabs-item" :class="sealType === 2 ? 'tab-active' : ''" @click="toggleType(2)">
              骑缝章
            </li>
          </ul>
          <div class="seal-name" v-if="sealType === 0">
            <div class="seal-img user-sign-container" id="scrollWarp1">
              <!-- 添加 签名 -->
              <ul class="nameImg-list">
                <li @touchstart="touchStart($event, seal, i)" @touchmove="touchMove($event)"
                  @touchend="touchEnd($event)" :class="currentSeal == seal.signIdE
                    ? 'nameImg-item cur-seal'
                    : 'nameImg-item'
                    " v-for="(seal, i) in canUseUserSignList" :key="i">
                  <div class="tip" v-if="seal.defaultSignature">默认</div>
                  <div class="count" v-if="seal.count">{{ seal.count }}</div>
                  <img :src="seal.signatureKey" alt="" />
                </li>
              </ul>
              <!-- 添加 签名 -->
              <div class="add-user-sign" @click="addName" v-if="myInfo.userSignList.length < 4 && !signatureRequire">
                <SvgIcon type="add" class="add-sign-icon" />
                <span class="add-sign-text">制作新签名</span>
              </div>
            </div>

            <div v-if="!myInfo.userSignList || myInfo.userSignList.length === 0" class="noName" @click="addName">
              <img src="@/assets/imgs/add-icon.png" alt="" />
              您尚未可用签名，请先<span>制作新签名</span>
            </div>
          </div>
          <ul class="sealImg-list" v-else-if="sealType === 1">
            <div v-if="!getAssign" class="sealImg-box" id="scrollWarp2">
              <li @touchstart="touchStart($event, seal, i)" @touchmove="touchMove($event)" @touchend="touchEnd($event)"
                :class="currentSeal == seal.sealId
                  ? 'sealImg-item cur-seal'
                  : 'sealImg-item'
                  " v-for="(seal, i) in sealList" :key="i">
                <div class="tip" v-if="seal.sealName == '默认公章'">默认</div>
                <div class="count" v-if="seal.count">{{ seal.count }}</div>
                <img :src="seal.sealUrl" alt="" />
              </li>
              <li class="sealImg-item make-seal" v-if="showMakeSealButton" @click="handleToMakeSeal">
                <SvgIcon type="add" class="add-icon" />
                <span class="add-text">制作印章</span>
              </li>
            </div>
            <div v-else class="sealImg-box" id="scrollWarp2">
              <li @touchstart="touchStart($event, getAssign)" @touchmove="touchMove($event)"
                @touchend="touchEnd($event)" :class="currentSeal == getAssign.sealId
                  ? 'sealImg-item cur-seal'
                  : 'sealImg-item'
                  ">
                <div class="tip" v-if="getAssign.sealName == '默认公章'">
                  默认
                </div>
                <div class="count" v-if="getAssign.count">
                  {{ getAssign.count }}
                </div>
                <img :src="getAssign && getAssign.sealUrl" alt="" />
              </li>
            </div>
          </ul>
          <ul class="sealImg-list" v-else-if="sealType === 2">
            <div v-if="!getAssign" class="sealImg-box" id="scrollWarp3">
              <li @touchstart="touchStart($event, seal, i)" @touchmove="touchMove($event)" @touchend="touchEnd($event)"
                class="sealImg-item" :class="{
                  'sealImg-item-gary':
                    isHasSealType4 && checkedSealType4 !== seal.sealId,
                  'cur-seal': currentSeal == seal.sealId,
                }" v-for="(seal, i) in sealList" :key="i">
                <div class="tip" v-if="seal.sealName == '默认公章'">
                  默认
                </div>
                <div class="count" v-if="seal.count">{{ seal.count }}</div>
                <img :src="seal.sealUrl" alt="" />
              </li>
              <li class="sealImg-item make-seal" v-if="showMakeSealButton" @click="handleToMakeSeal">
                <SvgIcon type="add" class="add-icon" />
                <span class="add-text">制作印章</span>
              </li>
            </div>
            <div v-else class="sealImg-box" id="scrollWarp3">
              <li @touchstart="touchStart($event, getAssign)" @touchmove="touchMove($event)"
                @touchend="touchEnd($event)" :class="currentSeal == getAssign.sealId
                  ? 'sealImg-item cur-seal'
                  : 'sealImg-item'
                  ">
                <div class="tip" v-if="getAssign.sealName == '默认公章'">
                  默认
                </div>
                <div class="count" v-if="getAssign.count">
                  {{ getAssign.count }}
                </div>
                <img :src="getAssign.sealUrl" alt="" />
              </li>
              <li class="sealImg-item make-seal" v-if="showMakeSealButton" @click="handleToMakeSeal">
                <SvgIcon type="add" class="add-icon" />
                <span class="add-text">制作印章</span>
              </li>
            </div>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Toast } from 'vant'
import getSealRect from '@/utils/getSealRect'
import { translateSealWidthAndHeight } from '@/utils/pxtorem'
import { getBodyClientRect, getFileImgHeight } from '@/utils/dom'
import { paginationHeight } from '@/common/filePreviewConfig'
import { SIGN_SEAL_TYPE, USER_SIGNATURE_TYPE } from '@/common/signEnum'
import {
  isUserSignType,
  userSignatureDefaultHeight,
  userSignatureDefaultWidth,
} from '@/common/userSignatureConfig'

export default {
  inject: ['getWrapRect'],
  props: {
    sealList: {
      type: Array,
      default: () => [],
    },
    hasPosition: {
      type: Boolean,
      default: false,
    },
    tMatrix: {
      type: Array,
      default: () => [],
    },
    getFooterHeight: {
      type: Function,
      default: () => { },
    },
    signatureRequire: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      assignId: state => state.contractSign.assignId,
      pageNum: state => state.contractSign.pageNum,
      myInfo: state => state.user.myInfo,
      userInfo: state => state.user.userInfo,
      currentFile: state => state.contractSign.currentFile,
      signData: state => state.contractSign.currentFile.signData,
      currentPositionList: state =>
        state.contractSign.currentFile.currentPositionList,
      subId: state => state.contractSign.subId,
      // operateType 参与方操作类型.0-个人审批 1-个人签署 2-经办人签署 3-法人签署 4-企业签章 5-仅填写变量
      operateType: state => state.contractSign.operateType,
      checkedSealType4: state => state.contractSign.checkedSealType4,
      checkedSealType: state => state.contractSign.checkedSealType,
      scrollTop: state => state.contractSign.scrollTop,
      ratio: state => state.contractSign.ratio,
      ratio2: state => state.contractSign.ratio2,
      computedY: state => state.contractSign.computedY,
      getAssign() {
        const seal = this.sealList.filter(item => {
          return item.sealId === this.assignId
        })
        return seal[0]
      },
      showMakeSealButton() {
        return this.sealList.length < 8
      },
    }),
    // 筛选出能使用的签名
    canUseUserSignList() {
      return this.myInfo.userSignList.filter(signSeal => {
        // 如果不能使用是手写签名要过滤掉
        if (this.signatureRequire === 1) {
          return signSeal.type !== USER_SIGNATURE_TYPE.HANDWRITTEN_SIGNATURE
        }
        // 如果指定只能使用手写签名，则要过滤掉其他类型
        if (this.signatureRequire === 2) {
          return signSeal.type === USER_SIGNATURE_TYPE.HANDWRITTEN_SIGNATURE
        }
        return true
      })
    },
    isHasSealType4() {
      const { signList } = this.currentFile.signData
      let flag = false
      signList.forEach(element => {
        if (element.signType === 4) {
          flag = true
        }
      })
      return flag
    },
    type() {
      if (
        this.operateType === 1 ||
        this.operateType === 2 ||
        this.operateType === 3
      ) {
        return 1
      }
      return 2
    },
  },
  watch: {
    signData: {
      handler(newValue, oldValue) {
        let arr = []
        if (this.type === 1) {
          arr = this.myInfo.userSignList
        } else {
          if (this.sealType === 0) {
            arr = this.myInfo.userSignList
          } else if (this.getAssign) {
            arr = [this.getAssign]
          } else {
            arr = this.sealList
          }
        }
        if (this.sealType === 0) {
          arr.forEach(x => {
            const temp = newValue.signList.filter(item => {
              return item.sealId === x.signIdE
            })
            this.$set(x, 'count', temp.length)
          })
        } else {
          arr.forEach(x => {
            const temp = newValue.signList.filter(item => {
              return item.sealId === x.signIdE || item.sealId === x.sealId
            })
            this.$set(x, 'count', temp.length)
          })
        }
      },
      deep: true,
      immediate: true,
    },
    currentPositionList: {
      handler(newValue, oldValue) {
        let arr = []
        if (this.hasPosition) {
          if (this.sealType === 0) {
            arr = this.myInfo.userSignList
          } else if (this.getAssign) {
            arr = [this.getAssign]
          } else {
            arr = this.sealList
          }
        }
        if (this.sealType === 0) {
          // 个人签名
          arr.forEach(x => {
            const temp = newValue.filter(item => {
              return item.sealKey === x.signatureKey
            })
            this.$set(x, 'count', temp.length)
          })
        } else {
          arr.forEach(x => {
            const temp = newValue.filter(item => {
              return item.sealKey === x.sealUrl
            })
            this.$set(x, 'count', temp.length)
          })
        }
      },
      deep: true,
      immediate: true,
    },
    pageNum() {
      if (this.pageNum <= 1) {
        this.sealType = 1
      }
    },
  },
  data() {
    return {
      show: false,
      sealType: 1,
      sealData: {
        signType: '',
        sealKey: '',
        sealId: '',
      },
      position: {
        x: 0,
        y: 0,
      },
      touchPosition: {
        x: 0,
        y: 0,
      },
      wrapWidth: 0,
      wrapHeight: 0,
      currentSeal: null,

      imgListHeight: 0,
    }
  },
  mounted() {
    const { clientWidth } = getBodyClientRect()
    const imgWrap = document.getElementsByClassName('file-img-list')[0]
    const { height } = imgWrap.getBoundingClientRect()
    this.wrapWidth = clientWidth
    this.imgListHeight = height
  },
  methods: {
    handleToMakeSeal() {
      this.$router.push({ name: 'addSeal' })
    },
    changeMatrix(x, y) {
      this.$emit('changeMatrix', x, y)
    },
    handleLeft() {
      let dom = null
      if (this.type === 1) {
        dom = document.getElementById('scrollWarp1')
      } else if (this.sealType === 0) {
        dom = document.getElementById('scrollWarp1')
      } else if (this.sealType === 1) {
        dom = document.getElementById('scrollWarp2')
      } else {
        dom = document.getElementById('scrollWarp3')
      }
      const left = dom.scrollLeft
      this.setScrollLeft(dom, 'left')
      // dom.scrollLeft = left + 114
    },
    handleRight() {
      let dom = null
      if (this.type === 1) {
        dom = document.getElementById('scrollWarp1')
      } else if (this.sealType === 0) {
        dom = document.getElementById('scrollWarp1')
      } else if (this.sealType === 1) {
        dom = document.getElementById('scrollWarp2')
      } else {
        dom = document.getElementById('scrollWarp3')
      }
      const left = dom.scrollLeft
      this.setScrollLeft(dom, 'right')
      // dom.scrollLeft = left - 114
    },
    // 横向滚动条滑动效果
    setScrollLeft(dom, value) {
      let num = 114
      const clock = setInterval(() => {
        if (num !== 0) {
          if (value === 'left') {
            dom.scrollLeft += 2
          } else {
            dom.scrollLeft -= 2
          }
          num -= 2
        } else {
          clearInterval(clock)
        }
      }, 1)
    },
    getWidthAndHeight(value) {
      return this.translateSealWidthAndHeight(this.wrapWidth, value)
    },
    translateSealWidthAndHeight,
    close() {
      this.show = false
    },
    open() {
      this.show = true
    },
    addName() {
      // 签名数量等于4，提示用户不能添加
      const signCount = this.myInfo?.userSignList?.length
      if (signCount >= 4) {
        Toast('当前签名个数已满，请删除一个签名后再添加新签名')
        return
      }
      const { creditStatus, userId } = this.userInfo
      this.$router.push({
        path: '/addSignName',
        query: {
          creditStatus,
          userId,
          subId: this.subId,
        },
      })
    },
    toggleType(type) {
      this.sealType = type
    },
    getCurrentPage(y) {
      const top = this.getWrapRect()
      // const disY = y + this.scrollTop - 20
      const disY = y
      if (disY < this.computedY) {
        return {
          page: 1,
          y,
        }
      }
      let start = 0
      let page = 0
      // 计算页数，start 最后一定是完整的页码高度
      while (start < disY) {
        start += this.computedY + (page - 1) * paginationHeight * this.ratio
        page++
      }

      let curPageYPosition = start
      if (start > disY) {
        // 计算出在当前页上的位置
        // start - disY 是当前页下半部分的高度
        // 用这几页的高度，减去目标位置的高度，就是当前页的下部分的高度
        // 用当前页的高度减去下半部分的高度就是当前签章的位置
        curPageYPosition = this.computedY - (start - disY)
      }
      return {
        // page: Math.floor(disY / this.computedY) + 1,
        // y: disY % this.computedY,

        page,
        y: curPageYPosition,
      }
    },
    getCurrentY(y, page) {
      return y * this.ratio + (page - 1) * this.computedY - this.scrollTop
    },
    getCurrentX(x) {
      return x * this.ratio
    },
    touchStart(e, sealData) {
      this.currentSeal = sealData.signIdE || sealData.sealId
      e.preventDefault()
      if (this.type === 1) {
        this.sealData = {
          signType: this.operateType - 1,
          sealKey: sealData.signatureKey,
          sealId: sealData.signIdE,
          sealImg: sealData?.signatureImg,
        }
      } else {
        // 在签署签章的情况下，还要去签 个人签名
        if (this.sealType === 0) {
          this.sealData = {
            signType: 0,
            sealKey: sealData.signatureKey,
            sealId: sealData.signIdE,
            sealImg: sealData?.signatureImg,
          }
        } else {
          this.sealData = {
            signType: this.operateType - 1,
            sealKey: sealData.sealUrl,
            sealId: sealData.sealId,
            sealImg: sealData?.sealImg,
          }
        }
      }
      let el = e.target
      const element = e.targetTouches[0]
      if (
        e.target.getAttribute('class') !== 'nameImg-item' ||
        e.target.getAttribute('class') !== 'sealImg-item'
      ) {
        el = e.target.parentNode
      }
      this.li = el.cloneNode(true)
      this.touchPosition.x = element.clientX
      this.touchPosition.y = element.clientY
      this.position.x = el.getBoundingClientRect().left
      this.position.y = el.getBoundingClientRect().top
      this.left = el.getBoundingClientRect().left
      this.top = el.getBoundingClientRect().top
      this.li.style.cssText = `position: fixed;left: ${this.position.x}px; top: ${this.position.y}px; max-width: 98px;`
      document.body.appendChild(this.li)
      // document.querySelector('#scrollWrapId').style.overflowY = 'hidden'
      document.querySelector('#app').style.overflow = 'hidden'
    },
    touchMove(e) {
      const element = e.targetTouches[0]
      this.left = this.position.x + element.clientX - this.touchPosition.x
      this.top = this.position.y + element.clientY - this.touchPosition.y
      if (Math.abs(element.clientY - this.touchPosition.y) > 50) {
        this.isEnd = true
        // this.show = false
      }
      this.li.style.left = `${this.left}px`
      this.li.style.top = `${this.top}px`
      const height =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0

      this.top += height
    },
    touchEnd(e) {
      // document.querySelector('#scrollWrapId').style.overflowY = 'visible'
      document.querySelector('#app').style.overflow = 'visible'
      if (!this.li) return
      document.body.removeChild(this.li)
      if (this.hasPosition) {
        this.setPosition()
      } else {
        if (!this.isEnd) {
          // 点击触发
          this.setPosition()
        } else {
          this.onDrop(this.left, this.top)
        }
      }
    },
    getImgListHeight() {
      const { clientWidth } = getBodyClientRect()
      const imgWrap = document.getElementsByClassName('file-img-list')[0]
      const { height } = imgWrap.getBoundingClientRect()
      this.wrapWidth = clientWidth
      this.imgListHeight = height
    },
    setPosition() {
      // 指定签署位置的情况
      if (this.hasPosition) {
        if (this.sealType === 1 || this.sealType === 0) {
          // 页面章
          const temp = this.currentFile.currentPositionList.find(x => {
            return x.signType !== 4 && !x.sealKey
          })
          if (temp) {
            const left = this.getCurrentX(temp.x)
            const top = this.getCurrentY(temp.y, temp.page)
            // 在实际的拖章过程中 章的位置的 y 是基于当前页面的顶部开始的，这里的top是章在文件内容区域的top，所以要在这里将 y 的值补上 文件内容区域 距离页面顶部的高度，模拟手拖动章的真实位置
            this.onDrop(left, top + this.getWrapRect())
            let y = temp.y + (temp.page - 1) * this.computedY - 200

            // 边界判断
            // 当签章位置靠上时
            if (y < 0) y = 0

            // 当签章位置太靠下时
            this.getImgListHeight()
            const footerHeight = this.getFooterHeight()
            if (y > this.imgListHeight - Math.abs(footerHeight)) {
              y = this.imgListHeight - Math.abs(footerHeight)
            }
            this.changeMatrix(0, y)
            // document.documentElement.scrollTop = top
            // document.body.scrollTop = top
          }
        } else {
          // 骑缝章
          console.log('指定骑缝章')
          const temp = this.currentFile.currentPositionList.find(x => {
            return x.signType === 4 && !x.sealKey
          })
          if (temp) {
            const left = this.getCurrentX(temp.x)
            const top = this.getCurrentY(temp.y, temp.page)
            this.onDrop(left, top + this.getWrapRect())
            let y = temp.y + (temp.page - 1) * this.computedY - 200
            if (y < 0) y = 0
            this.changeMatrix(0, y)
          }
        }
      } else {
        // 未指定签署位置
        if (this.sealType === 1 || this.sealType === 0) {
          // 页面章
          const height =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0
          const left = (this.wrapWidth - 93 * this.ratio) / 2
          this.onDrop(left, height + left)
        } else {
          // 骑缝章
          const left = this.wrapWidth - 93 * this.ratio
          this.onDrop(left, left - 100)
        }
      }
    },
    async onDrop(x, y) {
      const offsetTop = this.getWrapRect()
      y -= offsetTop
      this.wrapHeight = getFileImgHeight()
      y = (-this.tMatrix[5] + y) / this.tMatrix[0]
      x = (-this.tMatrix[4] + x) / this.tMatrix[0]
      console.log('after', y)
      if (y < 0) {
        y = 0
      }
      this.isEnd = false
      const position = this.getCurrentPage(y)
      // eslint-disable-next-line prefer-const
      let { page, y: currentPageTop } = position
      const computedXmin = Math.ceil(20 * this.ratio2)
      const computedXMax = Math.ceil(126 * this.ratio2)
      const computedYMin = Math.ceil((20 + 43) * this.ratio2)
      const computedYMax = Math.ceil((76 + 43) * this.ratio2)

      const sealWidth = 132 * this.ratio2
      const sealHeight = 70 * this.ratio2
      // 指定签署位置的情况
      if (this.hasPosition) {
        if (this.sealType === 1 || this.sealType === 0) {
          // if (page > this.currentFile.fileKeyList.length) return
          // 非骑缝章
          // const normalSealTypePositionList = this.currentFile.currentPositionList.filter(
          //   currentPosition => currentPosition.signType !== 4
          // )
          const items = this.currentFile.currentPositionList.filter(
            currentPosition =>
              currentPosition.signType !== 4 && !currentPosition.sealKey
          )
          const item = items[0]
          if (item) {
            let h = 93
            let w = 93
            if (this.sealData?.sealImg) {
              const { width, height } = await getSealRect(
                this.sealData?.sealImg
              )
              w = width > 225 ? 225 : width
              h = width > 225 ? (225 / width) * height : height
            }
            // 如果是指定的位置是企业签章，但是用户盖的章是个人签名，要将 signType 改成 0
            // 并且要设置 userId
            if (this.sealData.signType === 0) {
              item.signType = this.sealData.signType
              item.userId = this.userInfo.userId
            }
            // 如果之前设置的签章为个人签名现在设置的是组织签章，则要将signType改回来，否组会有缩放的问题
            if (this.sealData.signType === 3 && item.signType === 0) {
              item.signType = this.sealData.signType
            }

            item.sealKey = this.sealData.sealKey
            item.sealImg = this.sealData?.sealImg
            item.sealId = this.sealData.sealId
            item.wrapWidth = this.wrapWidth

            if (isUserSignType(item.signType)) {
              if (item.width == null && item.height == null) {
                w = userSignatureDefaultWidth * 2
                h = userSignatureDefaultHeight * 2
                item.width = w
                item.height = h
              }
            } else {
              item.width = w
              item.height = h
            }
            item.x = item.originalX
            item.y = item.originalY
            item.skipTranslate = true
            console.log(item)
            return
          }
          Toast('签署位置已指定，请添加签名至指定位置')
          return
        }
        /* 指定骑缝章的情况 */
        // const sealType4List = this.currentFile.currentPositionList.filter(
        //   currentPosition => currentPosition.signType === 4
        // )
        const items = this.currentFile.currentPositionList.filter(
          currentPosition =>
            currentPosition.signType === 4 && !currentPosition.sealKey
        )
        const item = items[0]
        if (item) {
          let h = 93
          let w = 93
          if (this.sealData?.sealImg) {
            const { width, height } = await getSealRect(
              this.sealData?.sealImg
            )
            w = width
            h = height
          }
          item.sealKey = this.sealData.sealKey
          item.sealId = this.sealData.sealId
          item.sealImg = this.sealData?.sealImg
          item.width = w
          item.height = h
          this.$store.commit(
            'contractSign/setCheckedSealType4',
            this.sealData.sealId
          )
          return
        }
        Toast('签署位置已指定，请添加签名至指定位置')
        return
      }
      if (page > this.currentFile.fileKeyList.length) return
      // 骑缝章
      if (this.sealType === 2) {
        if (this.isHasSealType4) return
        let h = 93
        let w = 93
        if (this.sealData.sealImg) {
          const { width, height } = await getSealRect(this.sealData.sealImg)
          w = width > 225 ? 225 : width
          h = width > 225 ? (225 / width) * height : height
        }
        // const SurplusY = y % this.wrapHeight
        // if (SurplusY > this.wrapHeight - this.getWidthAndHeight(h)) {
        //   y -= this.getWidthAndHeight(h)
        // }
        this.$store.commit('contractSign/addSeal', {
          signType: 4,
          sealKey: this.sealData.sealKey,
          sealImg: this.sealData?.sealImg,
          sealId: this.sealData.sealId,
          page: 1,
          x: this.wrapWidth - this.getWidthAndHeight(w / this.pageNum),
          y: position.y,
          wrapWidth: this.wrapWidth,
          width: w,
          height: h,
        })
        this.$store.commit(
          'contractSign/setCheckedSealType4',
          this.sealData.sealId
        )
        return
      }
      let h = 93
      let w = 93
      if (this.sealData.sealImg) {
        const { width, height } = await getSealRect(this.sealData.sealImg)
        w = width > 225 ? 225 : width
        h = width > 225 ? (225 / width) * height : height
      }
      if (isUserSignType(this.sealData.signType)) {
        w = userSignatureDefaultWidth * 2
        h = userSignatureDefaultHeight * 2
      }
      // 拖动签章放开手指的时候要做边界判断
      // x < 0 说明出了左边界
      if (x < 0) {
        x = 0
      }
      // x + this.getWidthAndHeight(w) 签章的宽度 > wrapWidth 容器的宽度 说明出了右边界
      if (x > this.wrapWidth - this.getWidthAndHeight(w)) {
        x = this.wrapWidth - this.getWidthAndHeight(w)
      }
      // y < 0 说明出了上边界
      if (position.y < 0) {
        position.y = 0
      }
      if (position.y + this.getWidthAndHeight(h) + 20 > this.computedY) {
        position.y = this.computedY - this.getWidthAndHeight(h) - 20
      }
      console.log(w, h)
      this.$store.commit('contractSign/addSeal', {
        signType: this.sealData.signType,
        sealKey: this.sealData.sealKey,
        sealImg: this.sealData?.sealImg,
        sealId: this.sealData.sealId,
        page: position.page,
        x,
        y: position.y,
        dateFlag: 1,
        wrapWidth: this.wrapWidth,
        width: w,
        height: h,
        skipTranslate: isUserSignType(this.sealData.signType),
      })
    },
    toggle() {
      this.show = !this.show
      this.$emit('toggleShow', this.show)
    },
  },
}
</script>
<style lang="less" scoped>
.nameImg-item {
  height: 98px;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

.sealAndName {
  // border-top: 1px solid #e7e7e7;
  border-radius: 7px 7px 0px 0px;

  .selectname {
    text-align: right;
    height: 38px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: right;
    color: #1676ff;
    padding-top: 16px;
    padding-right: 20px;
    margin-bottom: 20px;
  }

  .wrap {
    // transition: all 0.4s;
    position: relative;

    .btn-left {
      background: #f7f7f7;
      position: absolute;
      bottom: 24px;
      left: 12px;
      z-index: 10;
      width: 24px;
      height: 98px;
      opacity: 1;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 9px;
        height: 13px;
      }
    }

    .btn-right {
      background: #f7f7f7;
      font-size: 26px;
      position: absolute;
      bottom: 24px;
      right: 12px;
      z-index: 10;
      width: 24px;
      height: 98px;
      opacity: 1;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 9px;
        height: 13px;
      }
    }
  }

  .header {
    width: 100vw;
    height: 59px;
    border: 1px solid #f5f5f5;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: bold;
    text-align: center;
    color: #111a34;
    background: #fff;
    line-height: 59px;
    letter-spacing: 0px;
    position: relative;
    border-radius: 7px 7px 0 0;
    box-shadow: 0px -10px 10px 0px rgba(88, 88, 88, 0.44);

    .icon {
      position: absolute;
      left: 16px;
      top: 20px;
      width: 36px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: right;
      color: #666666;
      line-height: 20px;
      flex-shrink: 0;
    }
  }

  .content {
    min-height: 154px;
    padding: 0 56px;
    position: relative;

    .seal-name {
      .user-sign-container {
        margin-bottom: 0 !important;
        padding-top: 10px !important;
      }
    }

    .noName {
      height: 109px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }

      font-size: 15px;
      font-family: PingFangSC,
      PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #677283;
      line-height: 21px;

      span {
        color: #1676ff;
      }
    }

    .tabs-list {
      width: 328px;
      transform: translateX(-30px);
      height: 32px;
      background: #ffffff;
      // border-bottom: 1px solid #f5f5f5;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 12px 0 12px;
      border-bottom: 1px solid #e2e4ea;

      .tabs-item {
        width: 100px;
        height: 32px;
        // border: 1px solid #677283;
        // border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #677283;
        line-height: 32px;

        &:first-child {
          // margin-right: 50px;
        }
      }

      .tab-active {
        position: relative;
        color: #1676ff;
        border-color: #1676ff;

        &:after {
          display: inline-block;
          content: '';
          position: absolute;
          left: calc(50% - 20px);
          bottom: 0;
          width: 40px;
          height: 2px;
          background: #1676ff;
        }
      }
    }

    .des {
      width: 100%;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #677283;
      line-height: 20px;
      margin-top: 13px;
    }

    .user-sign-container {
      padding-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      // padding-left: 16px;
      overflow-x: auto;
      z-index: 20;

      .add-user-sign {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 98px;
        height: 98px;
        border-radius: 4px;
        border: 1px dashed #c1c4cd;
        font-size: 14px;

        .add-sign-icon {
          font-size: 14px;
        }

        .add-sign-text {
          margin-top: 10px;
          color: #969799;
        }
      }

      .nameImg-list {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 24px;

        .nameImg-item {
          position: relative;
          flex-shrink: 0;

          .tip {
            position: absolute;
            top: 0;
            left: 0;
            width: 32px;
            height: 18px;
            background: #ebf3ff;
            border-radius: 2px;
            font-size: 11px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: center;
            color: #1676ff;
            line-height: 18px;
          }

          width: 98px;
          height: 98px;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #e2e4ea;
          object-fit: cover;
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }

          .check-wrap {
            position: absolute;
            bottom: 0;
            right: 0;
          }

          .count {
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 50%;
            font-size: 12px;
            text-align: center;
            color: #fff;
            background: #fe943d;
            position: absolute;
            right: -8px;
            top: -8px;
          }
        }
      }

      &.seal-img {
        padding-left: 16px;
        margin-bottom: 21px;
      }
    }

    .sealImg-list {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      // padding-left: 16px;
      // margin-bottom: 24px;
      overflow-y: auto;

      .sealImg-box {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding-left: 16px;
        margin-bottom: 21px;
        overflow-y: auto;
        padding-top: 10px;
      }

      .sealImg-item {
        position: relative;
        flex-shrink: 0;

        .tip {
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
          height: 18px;
          background: #ebf3ff;
          border-radius: 2px;
          font-size: 11px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #1676ff;
          line-height: 18px;
        }

        width: 98px;
        height: 98px;
        border-radius: 4px;
        background: #ffffff;
        border: 1px solid #e2e4ea;
        object-fit: cover;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 72px;
          height: auto;
        }

        .check-wrap {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        .count {
          width: 16px;
          height: 16px;
          line-height: 16px;
          border-radius: 50%;
          font-size: 12px;
          text-align: center;
          color: #fff;
          background: #fe943d;
          position: absolute;
          right: -8px;
          top: -8px;
        }

        &.make-seal {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          .add-icon {
            font-size: 14px;
          }

          .add-text {
            margin-top: 10px;
            color: #969799;
          }
        }
      }

      .sealImg-item-gary {
        -webkit-filter: grayscale(100%);
        -webkit-filter: grayscale(1);
        filter: grayscale(100%);
        filter: gray;
      }

      .sealImg-item-gary {
        -webkit-filter: grayscale(100%);
        -webkit-filter: grayscale(1);
        filter: grayscale(100%);
        filter: gray;
      }
    }

    .cur-seal {
      border-color: #1676ff !important;
    }
  }
}
</style>
